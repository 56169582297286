@import "font";
@import "colors";
@import "viewport";
@import "triangle";

body {
  margin: 0;
  font-family: "Avenir Next", sans-serif;
} 

b {
  font-weight: 600;
}

p {
  margin: .5em 0;
}

.header {
  padding: 11em 0 1em 0;
  position: relative;

  @include phone {
    padding: 8em 0 3em 0;
  }

  h1 {
    text-align: center;
    font-weight: 500;
    color: $accent;
    margin: 10px;
    @include phone {
      font-size: 2.5em;
    }
  }
  .links {
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin: 0;
  }

  li {
    display: inline-block;
    @include phone {
      font-size: 1.3em;
    }
  }
  a {
    position: relative;
    display: block;
    padding: 0 12px;
    text-decoration: none;
    color: $dark-text;
    transition: .2s;
    @include phone {
      padding: 0 8px;
    }
    i {
      display: block;
      z-index: 2;
    }
    .subtitle {
      transition: .2s;
      z-index: 0;
      color: white;
      display: inline-block;
      position: absolute;
      text-align: center;
      top: 200%;
      user-select: none;
      transform: translateX(-50%);
    }
    &:hover {
      color: $black;
      .subtitle {
        color: $black;
        z-index: 1;
        top: 100%;
      }
    }
  }
}

.about {
  margin: 0 auto;
  max-width: 42em;

  a {
    transition: .2s;
    text-decoration: none;
    color: $link;
    &:hover {
      color: lighten($link, 20);
    }
  }

  .button {
    border: 1px solid $dark-text;
    width: 110px;
    padding: .25em 0;
    text-align: center;
    color: $dark-text;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    @include triangle(up-right, 0, $dark-text, true);
    @include triangle(down-left, 0, $dark-text, true, before);

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border: 0 solid;
      transform: rotate(360deg);
      transition: .2s;
      z-index: -1;
    }

    &:before {
      bottom: 0;
      left: 0;
      border-color: transparent transparent transparent $dark-text
    }

    &:after {
      top: 0;
      right: 0;
      border-color: transparent $dark-text transparent transparent
    }

    &:hover {
      color: white;

      &:before, &:after {
        border-width: 1.875em 6.875em*1.05;
      }
    }
  }

  @include phone {
    max-width: none;
    margin: 0 10%;
  }
}
