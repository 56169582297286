@font-face {
  font-family: "Avenir Next";
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/AvenirNext-UltraLight.ttf);
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 300;
  font-style: italic;
  src: url(../fonts/AvenirNext-UltraLightItalic.ttf);
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/AvenirNext-Regular.ttf);
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  font-style: italic;
  src: url(../fonts/AvenirNext-Italic.ttf);
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/AvenirNext-Medium.ttf);
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/AvenirNext-MediumItalic.ttf);
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  font-style: normal;
  src: url(../fonts/AvenirNext-DemiBold.ttf);
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 600;
  font-style: italic;
  src: url(../fonts/AvenirNext-DemiBoldItalic.ttf);
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/AvenirNext-Bold.ttf);
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  font-style: italic;
  src: url(../fonts/AvenirNext-BoldItalic.ttf);
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 800;
  font-style: normal;
  src: url(../fonts/AvenirNext-Heavy.ttf);
}
@font-face {
  font-family: "Avenir Next";
  font-weight: 800;
  font-style: italic;
  src: url(../fonts/AvenirNext-HeavyItalic.ttf);
}