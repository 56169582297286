
$base-px-default: 16px !default;
$triangle-direction-default: right !default;
$triangle-size-default: $base-px-default !default;
$triangle-color-default: #222222 !default;
$triangle-element-default: after !default;
$position-default: null !default;

@mixin position($type,
                $top: $position-default,
                $right: $position-default,
                $bottom: $position-default,
                $left: $position-default) {
	position: $type;
	$allowed_types: absolute relative fixed;
	@if not index($allowed_types, $type) {
		@warn "Unknown position: #{$type}.";
	}
	@each $data in top $top, right $right, bottom $bottom, left $left {
		#{nth($data, 1)}: nth($data, 2);
	}
}

@mixin absolute($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(absolute, $top, $right, $bottom, $left);
}

@mixin relative($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(relative, $top, $right, $bottom, $left);
}

@mixin fixed($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(fixed, $top, $right, $bottom, $left);
}

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin square($size) {
	@include size($size, $size);
}

@mixin triangle($direction: $triangle-direction-default,
								$size: $triangle-size-default,
								$color: $triangle-color-default,
								$center: false,
								$element: $triangle-element-default) {
	&:#{$element} {
		@if not $center {
			position: absolute;
		}
		content:'';
		@include square(0);
		-webkit-transform: rotate(360deg);
		border-style: solid;
		@if $direction == up {
			border-width: 0 $size ($size*1.625) $size;
			border-color: transparent transparent $color transparent;
			@if $center {
				@include absolute(null,null,100%,50%);
				transform: translateX(-$size);
			}
		}
		@else if $direction == up-right {
			border-width: 0 $size $size 0;
			border-color: transparent $color transparent transparent;
			@if $center {
				@include absolute(0,0);
			}
		}
		@else if $direction == right {
			border-width: $size 0 $size ($size*1.625);
			border-color: transparent transparent transparent $color;
			@if $center {
				@include absolute(50%,null,null,100%);
				transform: translateY(-$size);
			}
		}
		@else if $direction == down-right {
			border-width: 0 0 $size $size;
			border-color: transparent transparent $color transparent;
			@if $center {
				@include absolute(null,0,0);
			}
		}
		@else if $direction == down {
			border-width: ($size*1.625) $size 0 $size;
			border-color: $color transparent transparent transparent;
			@if $center {
				@include absolute(100%,null,null,50%);
				transform: translateX(-$size);
			}
		}
		@else if $direction == down-left {
			border-width: $size 0 0 $size;
			border-color: transparent transparent transparent $color;
			@if $center {
				@include absolute(null,null,0,0);
			}
		}
		@else if $direction == left {
			border-width: $size ($size*1.625) $size 0;
			border-color: transparent $color transparent transparent;
			@if $center {
				@include absolute(50%,100%);
				transform: translateY(-$size);
			}
		}
		@else if $direction == up-left {
			border-width: $size $size 0 0;
			border-color: $color transparent transparent transparent;
			@if $center {
				@include absolute(0,null,null,0);
			}
		}
		@else {
			@warn "Available directions: up, up-right, right, down-right, down, down-left, left, up-left.";
		}
		@content;
	}
}